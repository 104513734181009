<template>
  <div
    :class="[
      'item-attr mb-2',
      isShowingEditUI ? 'interactive' : 'static',
      { 'placeholder--item-attr': placeholder },
      { unselected },
    ]"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
  >
    <div :class="['item-attr--title', { hover }]">{{ attr.title }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MenuItemAttr',
  components: {},
  props: {
    attr: {
      type: Object,
      required: true,
      default: () => {},
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    unselected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapGetters('editor', ['isShowingEditUI']),
  },
  methods: {
    mouseover() {
      if (this.placeholder) return
      this.hover = true
    }, // mouseover
    mouseleave() {
      if (this.placeholder) return
      this.hover = false
    }, // mouseleave
  },
}
</script>

<style lang="scss">
@import '@design';

.item-attr.interactive:not(.static--forced) {
  cursor: pointer;
}

.item-attr {
  user-select: none;
  &:hover:not(.unselected.static) {
    opacity: 0.5;
  }
}

.item-attr--title {
  $dim: 25px;

  width: $dim;
  height: $dim;
  font-size: 9px;
  line-height: $dim - 2px;
  color: $color-db-purple;
  text-align: center;
  border: 2px solid $color-db-purple;
  border-radius: 100%;
}

.placeholder--item-attr,
.unselected {
  cursor: pointer;
  opacity: 0.8;
  .item-attr--title {
    color: grey;
    border-color: grey;
    border-style: dotted;
  }
  &:hover {
    opacity: 1;
    .item-attr--title {
      border-style: solid;
    }
  }
}
</style>
